@charset "utf-8";

@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/NotoSansKR-Regular.woff2') format('woff2'), url('../fonts/NotoSansKR-Regular.woff') format('woff'),
		url('../fonts/NotoSansKR-Regular.otf') format('opentype');
}

@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/NotoSansKR-Medium.woff2') format('woff2'), url('../fonts/NotoSansKR-Medium.woff') format('woff'),
		url('../fonts/NotoSansKR-Medium.otf') format('opentype');
}

@font-face {
	font-family: 'Noto Sans KR';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/NotoSansKR-Bold.woff2') format('woff2'), url('../fonts/NotoSansKR-Bold.woff') format('woff'),
		url('../fonts/NotoSansKR-Bold.otf') format('opentype');
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
textarea,
button {
	margin: 0;
	padding: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
html {
	height: 100%;
	font-size: 10px;
	overflow: hidden;
}
body {
	min-width: 320px;
	height: 100%;
	-webkit-text-size-adjust: none;
	-moz-text-size-adjust: none;
	-ms-text-size-adjust: none;
	text-size-adjust: none;
}
body,
th,
td,
input,
select,
textarea,
button {
	font-family: 'Noto Sans KR', 'Apple SD Gothic Neo', sans-serif;
	font-size: 1.4rem;
	color: #282828;
	line-height: 1;
	letter-spacing: -0.33px;
}
fieldset,
img {
	border: 0 none;
}
img {
	vertical-align: top;
}
dl,
ul,
ol,
menu,
li {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
input,
select,
textarea,
button {
	font-size: 100%;
	vertical-align: middle;
}
input[type='text'],
input[type='password'],
input[type='submit'],
input[type='search'] {
	-webkit-appearance: none;
	border-radius: 0;
}
input:checked[type='checkbox'] {
	background-color: #666;
	-webkit-appearance: checkbox;
}
button,
input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file'] {
	-webkit-appearance: button;
	border-radius: 0;
}
input[type='search']::-webkit-search-cancel-button {
	-webkit-appearance: none;
}
button {
	border: 0 none;
	background-color: transparent;
	cursor: pointer;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
address,
caption,
cite,
code,
dfn,
em,
var {
	font-style: normal;
	font-weight: normal;
}
a {
	text-decoration: none;
}
a:active,
a:hover {
	text-decoration: none;
}
mark {
	background-color: transparent;
}
.ico_comm {
	overflow: hidden;
	display: inline-block;
	background-size: contain;
	background-repeat: no-repeat;
	vertical-align: top;
	text-indent: -9999px;
}
/* placeholder 컬러 변경 */
input::-webkit-input-placeholder {
	color: #8c8e90;
} /* chrome, Edge */
input:-moz-placeholder {
	color: #8c8e90;
} /* firefox 4 t0 18 */
input::-moz-placeholder {
	color: #8c8e90;
} /* firefox 19+ */
input:-ms-input-placeholder {
	color: #8c8e90;
} /* IE 10, 11 */
input::-ms-input-placeholder {
	color: #8c8e90;
} /* Edge */
input::placeholder {
	color: #8c8e90;
	opacity: 1;
} /* opacity - 파이어폭스에서 뿌옇게 나오는 현상 방지*/
/* 포커스 시 placeholder 숨기기 - 필요에 따라 사용 */
input:focus::-webkit-input-placeholder {
	color: transparent;
}
input:focus:-moz-placeholder {
	color: transparent;
}
input:focus::-moz-placeholder {
	color: transparent;
}
input:focus:-ms-input-placeholder {
	color: transparent;
}
input:focus::-ms-input-placeholder {
	color: transparent;
}
input:focus::placeholder {
	color: transparent;
}
.screen_out {
	overflow: hidden;
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	line-height: 0;
	white-space: normal;
	word-wrap: break-word;
	word-break: break-all;
	clip: rect(0, 0, 0, 0);
}

/* common */
.point_txt {
	color: #347ee7;
}
.inp_blind {
	position: absolute;
	top: auto;
	left: auto;
	width: 0;
	height: 0;
	opacity: 0;
	cursor: pointer;
	border: 0;
	outline: 0;
	z-index: 1;
}
.search_keyword {
	font-weight: 500;
}

/* icon */
.ico_bot_logo {
	width: 135px;
	height: 30px;
	background-image: url(../images/ico_chat_logo.png);
	position: relative;
	top: -3px;
}
.ico_bot_logo_en {
	width: 266px;
	height: 30px;
	background-image: url(../images/ico_chat_logo_long_en.png);
	position: relative;
	top: -3px;
}

@media (max-width : 450px){
	.ico_bot_logo_en{
		width : 135px;
		background-image: url(../images/ico_chat_logo_en.png);
	}
}

.ico_chatbot {
	width: 40px;
	height: 40px;
	background-image: url(../images/ico_profile.png);
}
.ico_menu {
	width: 24px;
	height: 24px;
	background-image: url(../images/ico_menu.png);
}
.ico_language {
	width: 24px;
	height: 24px;
	background-image: url(../images/ico_language.png);
}


.ico_send {
	width: 20px;
	height: 20px;
	background-image: url(../images/ico_send.png);
}
/*
.ico_star_on{width:15px;height:15px;background-image:url(../images/ico_star_on.png)}
*/
.ico_close {
	width: 24px;
	height: 24px;
	background-image: url(../images/ico_close.png);
}
.ico_w_close {
	width: 24px;
	height: 24px;
	background-image: url(../images/ico_w_close.png);
}
.ico_arrow {
	position: absolute;
	right: 10px;
	width: 16px;
	height: 16px;
	background-image: url(../images/ico_arrow_down.png);
}
/*
.ico_qna{width: 20px;height: 20px;background-image:url(../images/ico_arrow_up2.png);}
*/
.ico_setting {
	width: 16px;
	height: 16px;
	background: url(../images/ico_setting.png) right center no-repeat;
	background-size: contain;
	position: absolute;
	top: 6px;
	right: 10px;
}
.ico_info {
	width: 16px;
	height: 16px;
	background: url(../images/ico_info.png) 0 0 no-repeat;
	background-size: contain;
	position: absolute;
	right: 10px;
	top: 6px;
}
/*
.ico_delete{width:14px;height:14px;background-image:url(../images/ico_delete.png);}
*/

/* header */
.chat_header {
	position: relative;
	background-color: #00462a;
}
.h_tit {
	padding: 16px 20px;
	height: 56px;
	text-align: center;
	box-sizing: border-box;
}
.language_box {
	position: absolute;
	left: 20px;
	top: 18px;
}
.btn_language {
	color: #ffffff;
	font-weight: 500;
	line-height: 1.3;
}
.btn_language .ico_arrow {
	margin: 0 0 0 4px;
}
.language_inner_box {
	display: none;
	position: fixed;
	top: 56px;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(44, 44, 44, 0.1);
	z-index: 300;
}
.language_list {
	position: absolute;
	left: 0;
	top: 0;
	width: 119px;
	background-color: #fff;
	z-index: 101;
	border: solid 1px #d9d9d9;
	box-sizing: border-box;
}
.language_item .language_link {
	display: block;
	padding: 10px 20px;
	color: #fff;
	height: 40px;
	box-sizing: border-box;
	color: #282828;
	line-height: 1.5;
	font-weight: bold;
}
.language_item + .language_item {
	border-top: 1px solid #d9d9d9;
}
.language_item.is_selected .language_link,
.language_item .language_link:hover {
	background-color: #f0e8fc;
	color: #00462a;
}
.btn_language.is_active .ico_arrow {
	background-image: url(../images/ico_arrow_up.png);
}
.btn_language.is_active + .language_inner_box {
	display: block;
}

.btn_unit {
	position: absolute;
	top: 16px;
	right: 20px;
}
.sub_menu_box {
	/*display: none;*/
	position: fixed;
	top: 0px;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	background-color: rgba(44, 44, 44, 0.1);
}
.sub_menu_inner {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 10px 16px;
	width: 188px;
	background-color: #fff;
	box-sizing: border-box;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
	z-index: 500;
	display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.profile_box {
    padding-top: 30px;
    text-align: center;
    flex-grow: 1;
    /*flex-basis: 185px;*/
    flex-shrink: 0;
}
.chat_logo_box {
	display: block;
	margin: 0 auto 10px;
	/*
	width: 96px;
	height: 96px;
	*/
	width: 80px;
	height: 80px;
}
.chat_logo_box > img {
	max-width: 100%;
}
.profile_name {
	font-size: 1.7rem;
	font-weight: bold;
	letter-spacing: -0.4px;
}
.btn_login {
	display: block;
	margin-top: 16px;
	width: 100%;
	/*height: 40px;*/
	padding: 10px 0;
	border-radius: 8px;
	border: 1px solid #c2a2f1;
	background: #c2a2f1;
	color: #fff;
	font-weight: 500;
}
.sub_menu_list {
	/*
	margin: 50px 0 47px;
	position: fixed;
	bottom: 90px;
	*/
	width: 156px;
	overflow: hidden;
}
.sub_menu_item {
	/*
	position: relative;
	text-align: center;
	*/
}
.sub_menu_item + .sub_menu_item {
	margin-top: 6px;
}
.sub_menu_link {
	display: block;
	color: #c2a2f1;
	/*padding: 10px 0;*/
	height: 36px;
	border-radius: 8px;
	border: 1px solid #c2a2f1;
	box-sizing: border-box;
	line-height: 1.2;
	font-weight: bold;
	width: 100%;
}
.sub_menu_link:hover,
.sub_menu_link.on {
	background: #c2a2f1;
	color: #fff;
}
.sub_btn_group {
	/*
	position: fixed;
	bottom: 20px;
	*/
	width: 160px;
	padding-top:10px;
}
.sub_btn_group .setting_list .list_item {
	height: 30px;
	padding: 6px 10px;
	border-radius: 6px;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	font-size: 12px;
	position: relative;
}
.sub_btn_group .setting_list .list_item:after {
	content: '';
	display: block;
	clear: both;
}
.sub_btn_group .list_item + .list_item {
	margin-top: 6px;
}
.sub_btn_group .sub_btn {
	float: left;
	line-height: 1.4;
	cursor: pointer;
	color: #8c8e90;
	font-weight: 500;
	letter-spacing: -0.28px;
}
.sub_btn_group .sub_btn .color_set {
	position: absolute;
	top: 6px;
	right: 30px;
	width: 16px;
	height: 16px;
	border-radius: 4px;
}
.sub_btn_group .sub_btn .color_set.c1 {
	background-color: #00462a;
}
.sub_btn_group .sub_btn .color_set.c2 {
	background-color: #c1733a;
}
.sub_btn_group .sub_btn .color_set.c3 {
	background-color: #b295dc;
}

.sub_btn_group .sub_btn .ico_arrow {
	position: absolute;
	right: 10px;
	top: 6px;
}
.sub_btn_group .is_active.ico_arrow {
	background-image: url(../images/ico_arrow_up.png);
}

.sub_btn_group .set_list {
	position: absolute;
	top: 26px;
	right: 0;
	width: 100px;
	height: 40px;
	padding: 10px;
	border-radius: 6px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
	background-color: #fff;
	box-sizing: border-box;
	z-index: 200;
	display: none;
}
.sub_btn_group .set_list:after {
	content: '';
	display: block;
	clear: both;
}
.sub_btn_group .set_list li {
	float: left;
}
.sub_btn_group .set_list li + li {
	margin-left: 8px;
}
.sub_btn_group .set_list li > a {
	display: block;
	width: 20px;
	height: 20px;
	border-radius: 4px;
}
.sub_btn_group .set_list li > a.c1 {
	background-color: #00462a;
}
.sub_btn_group .set_list li > a.c2 {
	background-color: #c1733a;
}
.sub_btn_group .set_list li > a.c3 {
	background-color: #b295dc;
}

.sub_btn_group .set_list li > a:hover {
	border: 2px solid #282828;
}

/*
.btn_more{padding:6px 8px}
.btn_more .ico_arrow{width:11px;height:7px;background-image:url(../images/ico_arrow_down3.png)}
.btn_more.is_active .ico_arrow{background-image:url(../images/ico_arrow_up3.png)}\
*/

.btn_close {
	position: absolute;
	right: 10px;
	top: 12px;
	padding: 5px;
}
.logout_link {
	display: inline-block;
	margin: 2px 0 0 4px;
	vertical-align: top;
}
.logout_link:before {
	display: inline-block;
	width: 16px;
	height: 16px;
	background-image: url(../images/ico_logout.png);
	background-size: contain;
	vertical-align: top;
	content: '';
}

.dimmed {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(44, 44, 44, 0.1);
	z-index: 1000;
}
.dimmed.guide {
	background: rgba(0, 0, 0, 0.6);
}
.layer_wrap {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	border-radius: 20px;
	z-index: 2000;
}
.layer_inner {
	overflow: hidden;
	width: 320px;
	vertical-align: middle;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 3000;
}
.btn_close.layer {
	position: absolute;
	top: 8px;
	right: 10px;
	padding: 0;
}
.layer_wrap.guide .layer_inner {
	width: auto;
	position: static;
	top: 0;
	left: 0;
	transform: none;
}
.layer_wrap.guide .layer_cont {
    background: transparent;
    padding: 50px 0 0 0;
    width: 312px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.layer_wrap.guide .layer_btn_group {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}
.layer_wrap.guide .layer_btn_group > button {
    color: #f4f4f4;
    display: inline-block;
    height: 30px;
    padding: 5px 10px;
    border-radius: 18px;
    border: solid 2px#fff;
    box-sizing: border-box;
    font-weight: bold;
}
.layer_wrap.guide .layer_btn_group .btn_never_view {
    margin-right: 60px;
}

.layer_wrap.guide .layer_cont > img {
	max-height: 100%;
	max-width: 100%;
}


.layer_top {
	height: 40px;
	padding: 10px;
	border-radius: 6px 6px 0 0;
	background-color: #b38bee;
	box-sizing: border-box;
}
.layer_h_tit {
	display: block;
	font-size: 1.4rem;
	color: #fff;
	line-height: 1.3;
	font-weight: 500;
	text-align: center;
}
.layer_cont {
	padding: 10px 20px 20px 20px;
	min-height: 364px;
	background-color: #fff;
	text-align: center;
	box-sizing: border-box;
	/*
	border-radius: 0 0 6px 6px;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	*/
}
.message_set_item {
	position: relative;
	text-align: left;
	font-weight: 500;
	color: #282828;
	height: 40px;
	padding: 10px 20px;
	box-sizing: border-box;
	line-height: 1.3;
}
.message_set_item + .message_set_item {
	border-top: 1px solid #e8e8e8;
}
.message_set_item .btn_control {
	right: 20px;
}

.tab_menu_box {
	height: 53px;
	border-radius: 5px;
	background: #fff;
}
.tab_menu_list {
	display: table;
	table-layout: fixed;
	width: 100%;
	height: 40px;
	background: #e6e6e6;
	border-radius: 6px;
	padding: 4px;
	box-sizing: border-box;
}
.tab_menu_list .tab_menu_item {
	display: table-cell;
	text-align: center;
}
.tab_menu_list .tab_menu_item .tab_menu_link {
	display: block;
	line-height: 1.4;
	letter-spacing: -0.33px;
	box-sizing: border-box;
	font-weight: bold;
	color: #131722;
	letter-spacing: -0.22px;
}
.tab_menu_list .tab_menu_item .tab_menu_link:hover span,
.tab_menu_list .tab_menu_item .tab_menu_link:active span,
.tab_menu_list .tab_menu_item.active .tab_menu_link span {
	display: inline-block;
	background: #fff;
	padding: 6px 0;
	min-width: 132px;
	box-sizing: border-box;
	border-radius: 6px;
	box-shadow: 0 2px 4px 0 rgba(19, 23, 34, 0.2);
}

.tab_cont {
	display: none;
}
.tab_cont .message_set {
	/* padding:22px 20px 20px; */ /* border-left: 1px solid #e8e8e8; */ /* border-bottom: 1px solid #e8e8e8; */ /* border-right: 1px solid #e8e8e8; */
	margin-top: 14px;
	border-radius: 6px;
	border: solid 1px #e8e8e8;
	height: 282px;
	overflow-y: auto;
}
.tab_cont.active {
	display: block;
}

/* content */
.chat_wrap {
	height: 100%;
}
.chat_container {
	overflow-x: hidden;
	overflow-y: auto;
	position: absolute;
	left: 0;
	top: 56px;
	right: 0;
	bottom: 92px;
	background: #f7f8f8;
}
.chat_content {
	padding: 20px 10px;
	height: 100%;
	box-sizing: border-box;
}

.chat_content:after {
	content: '';
	display: block;
	clear: both;
}

.menual_area {
	/*
	overflow-y: hidden;
	overflow-x: hidden;
	*/
	/*
	padding-left: 7px;
	margin: 0 -15px 0 -7px;
	*/
	/*
	white-space: nowrap;
	*/
	margin: 0 0 0 36px;
}
.menual_area .bot_box {
	margin: 0;
	width: 260px;
}
.menual_area .bot_box .bot_txt {
	display: flex;
	flex-direction: column;
    justify-content: space-between;
	vertical-align: top;
	margin-bottom: 5px;
	width: 234px;
	min-width: 234px;
}

.bot_wrap,
.user_wrap {
	margin-bottom: 10px;
}
.bot_wrap + .user_wrap,
.bot_wrap + .bot_wrap {
	clear: both;
}
.bot_wrap {
	position: relative;
	float: left;
	/*max-width: 85%;*/
	width:85%;
}
.bot_wrap .bot_flex {
	display: flex;
	flex-direction: column;
}

.bot_wrap:after {
	content: '';
	display: block;
	clear: both;
}

.bot_wrap .bot_name {
	font-weight: 500;
	display: inline-block;
	margin: 14px 0 0 6px;
}
.bot_box {
	margin: 0 0 0 36px;
	/*position: relative;*/
}

/*그리팅관련 추가*/
.ico_greeting{float:left;width:100px;height:126px;}
.ico_greeting:after{
	content:"";
	position:absolute;
	top: 16px;
	left: 91px;
	display:inline-block;
	width:29px;
	height:27px;
	background:url(../images/ico_chat_triangel3.png) 0 0 no-repeat;background-size:contain;
	z-index:1;
}

.the_green .ico_greeting:after{
	background:url(../images/ico_chat_triangel.png) 0 0 no-repeat;
	background-size:contain;
}

.the_brown .ico_greeting:after{
	background:url(../images/ico_chat_triangel2.png) 0 0 no-repeat;
	background-size:contain
}

.greeting_bot_box{
	min-width: 0 !important;
	width: calc(100% - 149px) !important;
	margin-left:13px;
	

}
.bot_box.greeting{
	margin-bottom:6px !important;
	float: left;
	/*max-width: 100%;*/
	line-height: 1.3;
	position: relative;
	width: 95%;
    min-width: 275px;
}
/*//그리팅관련 추가*/

.bot_box .bot_txt {
	float: left;
	/*max-width: 100%;*/
	background: #FBF8FE;
	padding: 10px;
	margin-bottom: 5px;
	border-radius: 10px;
	line-height: 1.3;
	position: relative;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	width: 95%;
    min-width: 240px;
}
.the_green .bot_box .bot_txt {
	background: #F4F9F7;
}
.the_brown .bot_box .bot_txt {
	background: #FCF7F3;
}

.bot_box .bot_txt.en {
	word-break: break-all;
}
.bot_box .bot_txt + .bot_txt {
	clear: both;
	margin-top: 6px;
}

.bot_box .bot_txt + .bot_txt.ui_list {
	margin-top: 10px;
	min-width: 254px;
}
.bot_txt.ui_list {
	padding: 0;
}
.bot_txt.ui_list .ui_top {
	background-color: #c2a2f1;
	border-radius: 8px 8px 0 0;
	text-align: center;
	padding: 6px 0;
	font-size: 12px;
	font-weight: 500;
	color: #fff;
	border: solid 1px #c2a2f1;
}
.bot_txt.ui_list .ui_cont {
	padding: 10px;
}
.bot_txt.ui_list .ui_cont:after {
	content: '';
	display: block;
	clear: both;
}
.bot_txt.ui_list .ui_cont .tit_box strong {
	letter-spacing: -0.28px;
	font-size: 12px;
}
.bot_txt.ui_list .ui_cont .tit_box + .list_box {
	margin-top: 10px;
}
.bot_txt.ui_list .list_box li:after {
	content: '';
	display: block;
	clear: both;
}
.bot_txt.ui_list .list_box li + li {
	margin-top: 10px;
}
.bot_txt.ui_list .list_box .list_tit_box {
	float: left;
	width: calc(100% - 60px);
	overflow: hidden;
}
.bot_txt.ui_list .list_box .img_box {
	float: right;
	height: 40px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
}
.bot_txt.ui_list .list_box .img_box > img {
	height: 100%;
}
.bot_txt.ui_list .list_box .list_tit_box strong {
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;
	overflow: hidden;
}

.bot_txt.ui_list .list_btn_group {
	margin-top: 16px;
}
.bot_txt.ui_list .list_btn_group.w_half .btn {
	float: left;
	width: 50%;
}
.bot_txt.ui_list .list_btn_group.w_half .btn + .btn {
	width: calc(50% - 6px);
	margin-left: 6px;
}
.bot_txt.ui_list .list_btn_group .btn {
	width: 100%;
	height: 40px;
	padding: 10px 0;
	border-radius: 8px;
	border: solid 1px #c2a2f1;
	background-color: #fff;
	color: #c2a2f1;
	line-height: 1.29;
	font-weight: bold;
}
.bot_txt.ui_list .list_btn_group .btn:hover,
.bot_txt.ui_list .list_btn_group .btn.on {
	background: #c2a2f1;
	color: #fff;
	font-weight: 500;
}

.bot_txt.item .img_box {
	height: 80px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
}
.bot_txt.item .txt_area {
	margin-top: 10px;
	height: 160px;
}

.btn_group {
	margin-top: 10px;
}
.btn_group .btn_link {
	display: block;
	min-width: 234px;
	padding: 10px 0;
	margin-bottom: 5px;
	/*height: 40px;*/
	border-radius: 8px;
	border: 1px solid #c2a2f1;
	background-color: #fff;
	box-sizing: border-box;
	text-align: center;
	color: #c2a2f1;
	font-weight: bold;
}
.btn_group .btn_link.en {
	font-size: 12px;
	letter-spacing: -0.26px;
	line-height: 1.5;
}
.btn_group.inner li + li {
	margin-top: 6px;
}
.btn_group .btn_link:hover,
.btn_group .btn_link.on {
	background-color: #c2a2f1;
	font-weight: bold;
	color: #fff;
}

.swiper-container.slide_wrap {
	overflow: visible;
}
.slide_wrap {
	clear: both;
	padding: 6px 0 !important;
}

.time {
	clear: both;
	display: block;
	font-size: 1.2rem;
	color: #8e8e8e;
	padding: 4px 0 0 36px;
}

.menu_box {
	margin-top: 16px;
}
.menu_box .slick-dots {
	left: 0;
	right: 0;
	width: auto;
}
.menu_box .slick-dots li {
	margin: -3px;
}
.menu_box .slick-dots li button:before {
	font-size: 30px;
	opacity: 1;
	color: #f0e8fc;
	line-height: 0;
}
.menu_box .slick-dots li.slick-active button:before {
	color: #b38bee;
}

.chat_menu {
	clear: both;
	/*margin: -9px 0 0 -6px;*/
}
.chat_menu:after {
	content: '';
	display: block;
	clear: both;
}
.chat_menu li {
	float: left;
	width: 33.33%;
}
.chat_menu li.clear_fix {
	clear: both;
}
.chat_menu .icon_box {
	display: block;
	margin: 9px 3px 0 3px;
	border-radius: 36px;
	padding: 14px 0;
	background-color: #fff;
	box-sizing: border-box;
	border: solid 1px #e8e8e8;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.flicking-viewport{
	padding: 3px;
}

.bot_txt.main .flicking-viewport{
	padding : 0 0 5px 0;
}
.chat_menu .icon_box.two {
	padding-top: 10px;
}
.icon_box .icon {
	display: block;
	width: 24px;
	height: 24px;
	background-repeat: no-repeat;
	background-size: contain !important;
	margin: 0 auto 8px auto;
}

.icon_box .icon.menu01 {
	width: 24px;
	background-image: url(../images/menu_ico01.png);
}
.icon_box .icon.menu02 {
	width: 24px;
	background-image: url(../images/menu_ico02.png);
}
.icon_box .icon.menu03 {
	width: 24px;
	background-image: url(../images/menu_ico03.png);
}
.icon_box .icon.menu04 {
	width: 24px;
	background-image: url(../images/menu_ico04.png);
}
.icon_box .icon.menu05 {
	width: 24px;
	background-image: url(../images/menu_ico05.png);
}
.icon_box .icon.menu06 {
	width: 24px;
	background-image: url(../images/menu_ico06.png);
}
.icon_box .icon.menu07 {
	width: 24px;
	background-image: url(../images/menu_ico07.png);
}
.icon_box .icon.menu08 {
	width: 24px;
	background-image: url(../images/menu_ico08.png);
}
.icon_box .icon.menu09 {
	width: 24px;
	background-image: url(../images/menu_ico09.png);
}
.icon_box .icon.menu10 {
	width: 24px;
	background-image: url(../images/menu_ico10.png);
}
.icon_box .icon.menu11 {
	width: 24px;
	background-image: url(../images/menu_ico11.png);
}
.icon_box .icon.menu12 {
	width: 24px;
	background-image: url(../images/menu_ico12.png);
}
.icon_box .icon.menu13 {
	width: 24px;
	background-image: url(../images/menu_ico13.png);
}
.icon_box .icon.menu14 {
	width: 24px;
	background-image: url(../images/menu_ico14.png);
}
.icon_box .icon.menu15 {
	width: 24px;
	background-image: url(../images/menu_ico15.png);
}
.icon_box .icon.menu16 {
	width: 24px;
	background-image: url(../images/menu_ico16.png);
}
.icon_box .icon.menu17 {
	width: 24px;
	background-image: url(../images/menu_ico17.png);
}
.icon_box .icon.menu18 {
	width: 24px;
	background-image: url(../images/menu_ico18.png);
}
.icon_box .icon.menu19 {
	width: 24px;
	background-image: url(../images/menu_ico19.png);
}
.icon_box .icon.menu20 {
	width: 24px;
	background-image: url(../images/menu_ico20.png);
}
.icon_box .icon.menu21 {
	width: 24px;
	background-image: url(../images/menu_ico21.png);
}
.icon_box .icon.menu22 {
	width: 24px;
	background-image: url(../images/menu_ico22.png);
	margin-bottom: 0;
}
.icon_box .icon.menu23 {
	width: 24px;
	background-image: url(../images/menu_ico23.png);
}
.icon_box .icon.menu24 {
	width: 24px;
	background-image: url(../images/menu_ico24.png);
}
.icon_box .icon.menu25 {
	width: 24px;
	background-image: url(../images/menu_ico25.png);
}
.icon_box .icon.menu26 {
	width: 24px;
	background-image: url(../images/menu_ico26.png);
}
.icon_box .icon.menu27 {
	width: 24px;
	background-image: url(../images/menu_ico27.png);
	margin-bottom: 0;
}

.icon_box .m_txt {
	display: block;
	text-align: center;
	color: #282828;
	font-size: 1.2rem;
}
.icon_box .m_txt.two {
	position: relative;
	top: 5px;
}
.icon_box:focus,
.icon_box:hover,
.icon_box:active,
.icon_box.on {
	background: #f0e8fc;
	border: 1px solid #f0e8fc;
}
.icon_box:focus .m_txt,
.icon_box:hover .m_txt,
.icon_box:active .m_txt,
.icon_box.on .m_txt {
	font-weight: bold;
}
.icon_box.two {
	padding-top: 9px;
}

.option_box {
	position: relative;
	clear: both;
	/*padding: 10px 0 0 36px;*/
	display: flex;
	flex-direction: row;
	align-items: center;
}
.btn_option {
	position: relative;
	display: inline-block;
	width: 198px;
	height: 40px;
	padding: 10px;
	font-size: 1.4rem;
	color: #8e8e8e;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 10px;
	box-sizing: border-box;
	text-align: left;
}
.btn_option .ico_arrow {
	top: 10px;
	width: 20px;
	height: 20px;
	background-image: url(../images/ico_arrow_down1.png);
}
.option_inner_box {
	display: none;
	position: absolute;
	left: 36px;
	top: 49px;
	width: 196px;
	border: 1px solid #d9d9d9;
	border-radius: 0 0 5px 5px;
	background-color: #fff;
	z-index: 10;
	overflow: hidden;
}
.option_item .option_link {
	display: block;
	height: 40px;
	padding: 10px;
	color: #282828;
	box-sizing: border-box;
	line-height: 1.3;
}
.option_item + .option_item {
	border-top: 1px solid #d9d9d9;
}
.option_item.is_selected .option_link,
.option_item .option_link:hover {
	background-color: #efefef;
}
.btn_option.is_active {
	border-radius: 10px 10px 0 0;
}
.btn_option.is_active .ico_arrow {
	background-image: url(../images/ico_arrow_up1.png);
}
.btn_option.is_active + .option_inner_box {
	display: block;
}
.btn_sel_ok {
	display: inline-block;
	padding: 10px 12px;
	font-size: 1.4rem;
	background-color: #c2a2f1;
	color: #fff;
	border-radius: 10px;
	margin-left: 2px;
}

.btn_outside {
	clear: both;
	padding: 6px 0 0 0;
	margin-left: 36px;
}
.btn_outside .outside_link {
	display: block;
	width: 100%;
	height: 40px;
	padding: 10px 16px;
	background: #fff;
	border: 1px solid #c2a2f1;
	border-radius: 8px;
	text-align: center;
	vertical-align: top;
	box-sizing: border-box;
	color: #c2a2f1;
	line-height: 1.29;
	font-weight: bold;
}
.btn_outside .outside_link:hover {
	background: #c2a2f1;
}
.btn_outside .outside_link:hover,
.btn_outside .outside_link.on {
	color: #fff;
	font-weight: 500;
}

.user_wrap:after {
	content: '';
	display: block;
	clear: both;
}
.user_box .user_txt {
	max-width: 88%;
	float: right;
	position: relative;
	padding: 10px;
	background: #e1d1f8;
	border-radius: 10px;
	line-height: 1.3;
	margin-bottom: 5px;
	color: #000;
}
.user_box .user_txt.en {
	max-width: 75%;
	word-break: break-all;
}
.user_box.arrow_none .user_txt:before {
	border-left: 0;
}
.user_box .user_txt + .user_txt {
	clear: both;
}
.time.user {
	text-align: right;
}

/* bottom */
.chat_bottom {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
}
.chat_bottom .inp_box {
	padding: 9px 10px;
	background-color: #e6e6e6;
	display: flex;
	flex-direction: row;
}
.inp_refresh {
	position: relative;
	width: 30px;
}
.btn_refresh {
	position: absolute;
	right: 0;
	top: 0;
	padding: 8px 4px 0px 0px;
}
.btn_refresh .ico_refresh {
	width: 24px;
	height: 24px;
	background-image: url(../images/ico_refresh.png);
	
}
.inp_txt {
	position: relative;
	height: 38px;
	width: 100%;
}
.inp_txt .inp {
	display: block;
	padding: 0 40px 0 12px;
	width: 100%;
	height: 100%;
	background-color: #fff;
	border: 0;
	border-radius: 5px;
	box-sizing: border-box;
}
.btn_send {
	position: absolute;
	right: 0;
	top: 0;
	padding: 10px 15px 8px;
}
.inp_txt .inp:focus + .btn_send .ico_send,
.btn_send.is_active .ico_send {
	background-image: url(../images/ico_send_on.png);
}

.qna_box {
	display: none;
}
.qna_item .qna_link {
	display: block;
	padding: 8px 20px;
	height: 36px;
	font-size: 1.4rem;
	color: #282828;
	line-height: 1.4;
	text-align: left;
	background-color: #fff;
	box-sizing: border-box;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	width: 100%;
	scroll-snap-align: start;
}
.qna_item .qna_link .num {
	display: inline-block;
	margin-right: 6px;
}
.qna_item .qna_link:hover,
.qna_item.is_selected .qna_link {
	background-color: #f0e8fc;
}

.qna_item .btn_delete {
	position: absolute;
	right: 10px;
	top: 50%;
	padding: 5px;
	margin-top: -12px;
	z-index: 10;
}
.ly_box_top {
	text-align: right;
	height: 36px;
	padding-right: 20px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 #d9d9d9;
	position: relative;
	z-index: 100;
}
.ly_box_tit {
	display: inline-block;
	padding: 12px 0;
	vertical-align: top;
	font-size: 1.2rem;
	color: #8e8e8e;
	font-weight: 500;
}
.ly_box_bottom {
	border-radius: 10px 10px 0 0;
	background: #da2127;
}
.ly_box_bottom.auto {
	display: none;
	background: #fff;
	border-radius: 0;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 #d9d9d9;
}
.ly_box_bottom .btn_control {
	position: relative;
	right: auto;
	top: auto;
	margin: 10px 0 9px 5px;
}
.auto_box {
	display: none;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 108px;
	scroll-snap-type: y mandatory;
	scroll-behavior: smooth;
}
.ly_box_bottom.is_open .auto_box {
	display: block;
}

.btn_control {
	position: absolute;
	right: 0;
	top: 50%;
	padding: 0 6px 1px 18px;
	margin-top: -10px;
	width: 32px;
	height: 17px;
	font-size: 1.2rem;
	color: #666;
	line-height: 1.5;
	background-color: #d9d9d9;
	border-radius: 11px;
	box-sizing: border-box;
}
.btn_control:before {
	position: absolute;
	left: 1px;
	top: 1px;
	width: 15px;
	height: 15px;
	border-radius: 10px;
	background-color: #fff;
	content: '';
	box-shadow: 2px 1px 4px -2px rgba(52, 51, 52, 0.2);
}
.btn_control.is_active {
	display: inline-block;
	padding: 0 18px 1px 6px;
	color: #fff;
	background-color: #00462a;
}
.btn_control.is_active:before {
	left: auto;
	right: 1px;
}

/* theme : the_brown */
.logout_link.the_brown:before {
	display: inline-block;
	width: 16px;
	height: 16px;
	background-image: url(../images/ico_logout1.png);
	background-size: contain;
	vertical-align: top;
	content: '';
}
.the_brown .ico_close {
	background-image: url(../images/ico_close1.png);
}
.the_brown .language_item.is_selected .language_link,
.the_brown .language_item .language_link:hover {
	background: #f3e3d8;
}
.the_brown .btn_login {
	border: 1px solid #c1733a;
	background: #c1733a;
}
.the_brown .sub_menu_link {
	color: #c1733a;
	border: 1px solid #c1733a;
	width: 100%;
}
.the_brown .sub_menu_link:hover,
.the_brown .sub_menu_link.on {
	color: #fff;
	background-color: #c1733a;
}
.the_brown .qna_item .qna_link:hover,
.the_brown .qna_item.is_selected .qna_link {
	background-color: #f3e3d8;
}
.the_brown .user_box .user_txt {
	background: #f3e3d8;
}
.the_brown .menu_box .slick-dots li button:before {
	color: #f3e3d8;
}
.the_brown .menu_box .slick-dots li.slick-active button:before {
	color: #c1733a;
}
.the_brown .chat_menu .icon_box:focus,
.the_brown .chat_menu .icon_box:hover,
.the_brown .chat_menu .icon_box:active,
.the_brown .chat_menu .icon_box.on {
	background: #f3e3d8;
	border: 1px solid #f3e3d8;
}
.the_brown .btn_group .btn_link {
	color: #c1733a;
	border: 1px solid #c1733a;
}
.the_brown .btn_group .btn_link:hover,
.the_brown .btn_group .btn_link.on {
	color: #fff;
	background-color: #c1733a;
}
.the_brown .btn_sel_ok {
	background-color: #c1733a;
}
.the_brown .btn_outside .outside_link {
	color: #c1733a;
	border: 1px solid #c1733a;
}
.the_brown .btn_outside .outside_link:hover {
	color: #fff;
	background-color: #c1733a;
}
.the_brown .bot_txt.ui_list .ui_top {
	color: #fff;
	background-color: #c1733a;
	border: 1px solid #c1733a;
}
.the_brown .bot_txt.ui_list .list_btn_group .btn {
	border: 1px solid #c1733a;
	color: #c1733a;
}
.the_brown .bot_txt.ui_list .list_btn_group .btn:hover,
.the_brown .bot_txt.ui_list .list_btn_group .btn.on {
	color: #fff;
	background-color: #c1733a;
}
.the_brown .layer_top {
	background-color: #c1733a;
}

/* theme : the_green */
.ico_bot_logo1 {
	width: 135px;
	height: 30px;
	/*background-image: url(../images/ico_chat_logo1.png);*/
	background-image: url(../images/ico_chat_logo.png);
	position: relative;
	top: -3px;
}
.ico_bot_logo_en1 {
	width: 135px;
	height: 30px;
	background-image: url(../images/ico_chat_logo_en1.png);
	position: relative;
	top: -3px;
}
.ico_language1 {
	width: 24px;
	height: 24px;
	background-image: url(../images/ico_language1.png);
}
.ico_menu1 {
	width: 24px;
	height: 24px;
	/*background-image: url(../images/ico_menu1.png);*/
	background-image: url(../images/ico_menu.png);
}
.ico_menu1 {
	width: 24px;
	height: 24px;
	/*background-image: url(../images/ico_menu1.png);*/
	background-image: url(../images/ico_menu.png);
}
.the_green .chat_header {
	background-color: #045738;
}
.logout_link.the_green:before {
	display: inline-block;
	width: 16px;
	height: 16px;
	background-image: url(../images/ico_logout2.png);
	background-size: contain;
	vertical-align: top;
	content: '';
}
.the_green .ico_close {
	background-image: url(../images/ico_close2.png);
}
.the_green .language_item.is_selected .language_link,
.the_brown .language_item .language_link:hover {
	background: #ccdad4;
}
.the_green .btn_login {
	border: 1px solid #00462a;
	background: #00462a;
}
.the_green .sub_menu_link {
	color: #00462a;
	border: 1px solid #00462a;
	width: 100%;
}
.the_green .sub_menu_link:hover,
.the_green .sub_menu_link.on {
	color: #fff;
	background-color: #00462a;
}
.the_green .qna_item .qna_link:hover,
.the_green .qna_item.is_selected .qna_link {
	background-color: #ccdad4;
}
.the_green .user_box .user_txt {
	background: #ccdad4;
}
.the_green .menu_box .slick-dots li button:before {
	color: #ccdad4;
}
.the_green .menu_box .slick-dots li.slick-active button:before {
	color: #00462a;
}
.the_green .chat_menu .icon_box:focus,
.the_green .chat_menu .icon_box:hover,
.the_green .chat_menu .icon_box:active,
.the_green .chat_menu .icon_box.on {
	background: #ccdad4;
	border: 1px solid #ccdad4;
}
.the_green .btn_group .btn_link {
	color: #00462a;
	border: 1px solid #00462a;
}
.the_green .btn_group .btn_link:hover,
.the_green .btn_group .btn_link.on {
	color: #fff;
	background-color: #00462a;
}
.the_green .btn_sel_ok {
	background-color: #00462a;
}
.the_green .btn_outside .outside_link {
	color: #00462a;
	border: 1px solid #00462a;
}
.the_green .btn_outside .outside_link:hover {
	color: #fff;
	background-color: #00462a;
}
.the_green .bot_txt.ui_list .ui_top {
	color: #fff;
	background-color: #00462a;
	border: 1px solid #00462a;
}
.the_green .bot_txt.ui_list .list_btn_group .btn {
	border: 1px solid #00462a;
	color: #00462a;
}
.the_green .bot_txt.ui_list .list_btn_group .btn:hover,
.the_green .bot_txt.ui_list .list_btn_group .btn.on {
	color: #fff;
	background-color: #00462a;
}
.the_green .layer_top {
	background-color: #00462a;
}

/* media */
@media (min-width: 501px) {
	.chat_menu .icon_box {
		border-radius: 36px;
	}
}

@media (max-width: 320px) {
	.layer_wrap.guide .layer_cont > img {
		max-width: 80%;
	}
}

@media (max-height: 667px) {
	.layer_wrap.guide .layer_cont > img {
		max-width: 70%;
	}
	/*
	.chat_logo_box {
		display: block;
		margin: 0 auto 10px;
		width: 80px;
		height: 80px;
	}
	*/
}
@media (max-height: 460px) {
	.layer_wrap.guide .layer_cont > img {
		max-width: 60%;
	}
}



.pagination ul {
	display: block;
	padding: 5px 0 0 0;
	margin: 0;
	text-align: center;
}
.pagination li {
	display: inline-block;
	background: #D1B9F5;
	list-style: none;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin: 5px 3px 5px 3px;
	transition: background-color ease 0.3s;
	cursor: pointer;
}
.pagination li.checked {
	background: #b38bee;
}
.the_green .pagination li {
	background: #99B5AA;
}
.the_green .pagination li.checked {
	background: #00462A;
}
.the_brown .pagination li {
	background: #E6C7B0;
}
.the_brown .pagination li.checked {
	background: #C1733A;
}


/*
.menual_area .bot_box .bot_txt:before {
	display: none;
	border-radius: 0 15px 15px;
}
*/

/* loading */
.three-balls {
	margin: 10px auto 0;
	width: 60px;
	text-align: center;
}
.three-balls .ball {
	margin-right: 1px;
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: inline-block;
	-webkit-animation: bouncedelay 3s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
	animation: bouncedelay 3s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
	background: #E1D1F8;
}
.three-balls .ball1 {
	margin-right: 1px;
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
.three-balls .ball2 {
	-webkit-animation-delay: -0.08s;
	animation-delay: -0.08s;
}
@keyframes bouncedelay {
	0% {
		bottom: 0;
		background-color: #E1D1F8;
	}
	16.66% {
		bottom: 12px;
		background-color: #D1B9F5;
	}
	33.33% {
		bottom: -5px;
		background-color: #D1B9F5;
	}
	50% {
		bottom: 12px;
		background-color: #B38BEE;
	}
	66.66% {
		bottom: -5px;
		background-color: #B38BEE;
	}
	83.33% {
		bottom: 12px;
		background-color: #E1D1F8;
	}
	100% {
		bottom: -5px;
		background-color: #E1D1F8;
	}
}
@-webkit-keyframes bouncedelay {
	0% {
		bottom: 0;
		background-color: #E1D1F8;
	}
	16.66% {
		bottom: 12px;
		background-color: #D1B9F5;
	}
	33.33% {
		bottom: -5px;
		background-color: #D1B9F5;
	}
	50% {
		bottom: 12px;
		background-color: #B38BEE;
	}
	66.66% {
		bottom: -5px;
		background-color: #B38BEE;
	}
	83.33% {
		bottom: 12px;
		background-color: #E1D1F8;
	}
	100% {
		bottom: -5px;
		background-color: #E1D1F8;
	}
}

.the_green .three-balls .ball {
	-webkit-animation: bouncedelay_green 3s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
	animation: bouncedelay_green 3s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
	background: #99B5AA;
}
.the_green .three-balls .ball1 {
	margin-right: 1px;
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
.the_green .three-balls .ball2 {
	-webkit-animation-delay: -0.08s;
	animation-delay: -0.08s;
}
@keyframes bouncedelay_green {
	0% {
		bottom: 0;
		background-color: #99B5AA;
	}
	16.66% {
		bottom: 12px;
		background-color: #66907F;
	}
	33.33% {
		bottom: -5px;
		background-color: #66907F;
	}
	50% {
		bottom: 12px;
		background-color: #00462A;
	}
	66.66% {
		bottom: -5px;
		background-color: #00462A;
	}
	83.33% {
		bottom: 12px;
		background-color: #99B5AA;
	}
	100% {
		bottom: -5px;
		background-color: #99B5AA;
	}
}
@-webkit-keyframes bouncedelay_green {
	0% {
		bottom: 0;
		background-color: #99B5AA;
	}
	16.66% {
		bottom: 12px;
		background-color: #66907F;
	}
	33.33% {
		bottom: -5px;
		background-color: #66907F;
	}
	50% {
		bottom: 12px;
		background-color: #00462A;
	}
	66.66% {
		bottom: -5px;
		background-color: #00462A;
	}
	83.33% {
		bottom: 12px;
		background-color: #99B5AA;
	}
	100% {
		bottom: -5px;
		background-color: #99B5AA;
	}
}

.the_brown .three-balls .ball {
	-webkit-animation: bouncedelay_brown 3s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
	animation: bouncedelay_brown 3s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
	background: #E6C7B0;
}
.the_brown .three-balls .ball1 {
	margin-right: 1px;
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
.the_brown .three-balls .ball2 {
	-webkit-animation-delay: -0.08s;
	animation-delay: -0.08s;
}
@keyframes bouncedelay_brown {
	0% {
		bottom: 0;
		background-color: #E6C7B0;
	}
	16.66% {
		bottom: 12px;
		background-color: #DAAB89;
	}
	33.33% {
		bottom: -5px;
		background-color: #DAAB89;
	}
	50% {
		bottom: 12px;
		background-color: #C1733A;
	}
	66.66% {
		bottom: -5px;
		background-color: #C1733A;
	}
	83.33% {
		bottom: 12px;
		background-color: #E6C7B0;
	}
	100% {
		bottom: -5px;
		background-color: #E6C7B0;
	}
}
@-webkit-keyframes bouncedelay_brown {
	0% {
		bottom: 0;
		background-color: #E6C7B0;
	}
	16.66% {
		bottom: 12px;
		background-color: #DAAB89;
	}
	33.33% {
		bottom: -5px;
		background-color: #DAAB89;
	}
	50% {
		bottom: 12px;
		background-color: #C1733A;
	}
	66.66% {
		bottom: -5px;
		background-color: #C1733A;
	}
	83.33% {
		bottom: 12px;
		background-color: #E6C7B0;
	}
	100% {
		bottom: -5px;
		background-color: #E6C7B0;
	}
}

/*
.mainguide {
	position: absolute;
	padding:0px;
	margin:0px;
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.85);
	width: 100vw;
	height: 100%;
	display: flex;
    align-items: center;
    justify-content: center ;
}

.guideimg {
	position: absolute;
    width: 80%;
    height: 80%;
	margin: 0 auto;

    background-image: Url("../images/use_tip.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}
*/